import React from 'react'
import { Layout, Stack, Main } from '@layout'
import CardList from '@components/CardList'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import Testimonial from '@widgets/Testimonial'
import Hero from '../components/Hero/Hero'
import AppleM1 from '../components/AppleM1/AppleM1'
import WorksOnArm from '../components/WorksOnArm/WorksOnArm'
import PackageCloud from '../components/PackageCloud/PackageCloud'
import { useBlogCategories } from '@helpers-blog'

const Posts = ({ data: { posts = {}, featuredPosts = {} },
  ...props
}) => {
  const { pageContext: { services = {} } = {} } = props
  const categories = useBlogCategories()

  return (
    <Layout {...props}>
      <Seo title='Home' />
      <Divider />
      <Stack>
        <Main>
          <Hero />
        </Main>
      </Stack>
      <Divider />
      <Stack>
        <Main>
          <CardList
            nodes={featuredPosts.nodes}
            limit={6}
            variant='vertical-cover'
            title='Featured this month'
            slider
            columns={[1, 2, 3, 3]}
            autoPlay
          />
        </Main>
      </Stack>
      <Divider />
      <Stack>
        <Main>
          <WorksOnArm />
        </Main>
      </Stack>
      <Divider space={4} />
      <Stack>
        {/* News Section */}
        <Main>
          {posts.group.length && posts.group.map((group, index) => (
            <React.Fragment key={`${group.categoryName}.list`}>
              {index % 5 === 2 ? (
              <Stack>
                <Main>
                  <CardList
                    nodes={group.nodes}
                    limit={4}
                    variant='horizontal-md'
                    title={group.categoryName}
                    key={group.categoryName}
                    columns={[1, 2, 2, 2]}
                    withTitleLink
                  />
                </Main>
              </Stack>
              ) :
              <Stack/>
              }
            </React.Fragment>
          ))}
        </Main>
      </Stack>
      <Divider space={3} />
      <Stack>
        <Main>
        <AppleM1 />
        </Main>
      </Stack>
      <Divider space={4} />
      <Stack>
        {/* Swift Section */}
        <Main>
          {posts.group.length && posts.group.map((group, index) => (
            <React.Fragment key={`${group.categoryName}.list`}>
              {index % 5 === 4 ? (
              <Stack>
                <Main>
                  <CardList
                    nodes={group.nodes}
                    limit={4}
                    variant='horizontal-md'
                    title={group.categoryName}
                    key={group.categoryName}
                    columns={[1, 2, 2, 2]}
                    withTitleLink
                  />
                </Main>
              </Stack>
              ) :
              <Stack/>
              }
            </React.Fragment>
          ))}
        </Main>
      </Stack>
      <Divider space={3} />
      <Stack>
        <Main>
          <PackageCloud />
        </Main>
      </Stack>
      <Divider space={4} />
      <Stack>
        {/* RPi Section */}
        <Main>
          {posts.group.length && posts.group.map((group, index) => (
            <React.Fragment key={`${group.categoryName}.list`}>
              {index % 5 === 3 ? (
              <Stack>
                <Main>
                  <CardList
                    nodes={group.nodes}
                    limit={4}
                    variant='horizontal-md'
                    title={group.categoryName}
                    key={group.categoryName}
                    columns={[1, 2, 2, 2]}
                    withTitleLink
                  />
                </Main>
              </Stack>
              ) :
              <Stack/>
              }
            </React.Fragment>
          ))}
        </Main>
      </Stack>
      <Divider space={3} />
      <Stack>
        <Main>
        <Testimonial />
        </Main>
      </Stack>
      <Divider space={4} />
      <Stack>
        {/* Apple Section */}
        <Main>
          {posts.group.length && posts.group.map((group, index) => (
            <React.Fragment key={`${group.categoryName}.list`}>
              {index % 5 === 0 ? (
              <Stack>
                <Main>
                  <CardList
                    nodes={group.nodes}
                    limit={4}
                    variant='horizontal-md'
                    title={group.categoryName}
                    key={group.categoryName}
                    columns={[1, 2, 2, 2]}
                    withTitleLink
                  />
                </Main>
              </Stack>
              ) :
              <Stack/>
              }
            </React.Fragment>
          ))}
        </Main>
      </Stack>
      <Divider space={3} />
    </Layout>
  )
}

export default Posts
