import React from 'react'
import { Link } from 'gatsby'
import { Box, Heading, Button } from 'theme-ui'
import Section from '@components/Section'

/**
 * Shadow me to add your own content
 */

const styles = {
  heading: {
    mb: 4,
    span: {
      color: `omegaDark`
    }
  },
  running: {
    color: `omegaDark`,
    fontWeight: `body`,
    width: `5/6`
  }
}

export default () => (
  <>
    <Section>
      <Heading variant='h1' sx={styles.heading}>
        Works On <span>Arm</span>
      </Heading>
      <Heading variant='h3' sx={styles.running}>
      Swift-Arm would like to thank the <b>Works on Arm</b> project for their ongoing support and supply of our awesome C.I. Server.
      </Heading>
    </Section>
    <Box variant='buttons.group' sx={styles.buttons}>
      <Button as={Link} to='https://www.worksonarm.com/'>
        Read More...
      </Button>
    </Box>
  </>
)
