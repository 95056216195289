import React from 'react'
import { Link } from 'gatsby'
import { Box, Heading, Button } from 'theme-ui'
import Section from '@components/Section'

/**
 * Shadow me to add your own content
 */

const styles = {
  heading: {
    mb: 4,
    span: {
      color: `omegaDark`
    }
  },
  running: {
    color: `omegaDark`,
    fontWeight: `body`,
    width: `5/6`
  }
}

export default () => (
  <>
    <Section>
      <Heading variant='h1' sx={styles.heading}>
        <br />
      </Heading>
      <Heading variant='h3' sx={styles.running}>
      Swift-Arm would like to thank the <b>Package Cloud</b> for their ongoing support and hosting of our Package Repository.
      </Heading>
    </Section>
    <br />
    <Box variant='buttons.group' sx={styles.buttons}>
      {/* <Button as={Link} to='https://www.worksonarm.com/'> */}
      <a href="https://packagecloud.io/"><img height="46" width="158" alt="Private NPM registry and Maven, RPM, DEB, PyPi and RubyGem Repository · packagecloud" src="https://packagecloud.io/images/packagecloud-badge.png" /></a>
      {/* </Button> */}
    </Box>
  </>
)
