import React from 'react'
//import { Link } from 'gatsby'
import { Heading } from 'theme-ui'
import Section from '@components/Section'
import { Box, Text, Card, Flex, Button } from 'theme-ui'
import { Link } from 'gatsby'

/**
 * Shadow me to add your own content
 */

const styles = {
  heading: {
    mb: 4,
    span: {
      color: `omegaDark`
    }
  },
  running: {
    color: `omegaDark`,
    fontWeight: `body`,
    width: `5/6`
  }
}

export default () => (
  <>
    <Section>
      <Heading variant='h1' sx={styles.heading}>
        Install <span>Swift</span> on Arm!
      </Heading>
      <Heading variant='h3' sx={styles.running}>
        <b>Swift</b> is an exciting Open Source coding language developed by <b>Apple</b>.
      </Heading>
      <Heading variant='h3' sx={styles.running}>
      <b>Swift-Arm</b> is all about getting <b>Swift</b> onto arm devices. This can be anything from a Raspberry Pi and IOT devices, to high performance cloud servers such as AWS and Equinix Metal.
      </Heading>
    </Section>
    <Box variant='buttons.group' sx={styles.buttons}>
      {/* <Button as={Link} to='https://docs.swift.org/swift-book/GuidedTour/GuidedTour.html'>
        Learn Swift
      </Button> */}
      <Button variant='white' as={Link} to='https://ko-fi.com/futurejones#'>
        Support this project
      </Button>
    </Box>
  </>
)
